import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { DeviceGroupService } from '@/App/Services/DeviceGroup.service'
import { DeviceGroupResponse } from '@/App/Services/interfaces/deviceGroup.interface'

const sharedStore = namespace('Shared')
const deviceGroupStore = namespace('DeviceGroup')

@Component
export default class DevicesGroupTable extends Vue {
  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @deviceGroupStore.State
  public readonly groups: DeviceGroupResponse[]

  @deviceGroupStore.Mutation
  private readonly setGroups: (devices: DeviceGroupResponse[]) => void

  @deviceGroupStore.Mutation
  private readonly destroyGroup: (_id: string) => void

  @deviceGroupStore.Mutation
  private readonly editGroup: (device: DeviceGroupResponse) => void

  public search = ''
  public isLoading = false
  public headers = [
    { text: this.$t('locationPage.deviceGroupsDialog.devicesGroupsTable.nameText').toString(), align: 'left', sortable: true, value: 'name' },
    { text: this.$t('locationPage.deviceGroupsDialog.devicesGroupsTable.actionText').toString(), value: 'left', align: 'center', sortable: false }
  ]

  public async onDelete(id: string): Promise<void> {
    const title = this.$t('locationPage.deviceGroupsDialog.devicesGroupsTable.deleteDeviceText').toString()
    // TODO maybe move this text from all component to constants
    const text = `
        ${this.$t('locationPage.deviceGroupsDialog.devicesGroupsTable.confirm1Text')}<br/>
        ${this.$t('locationPage.deviceGroupsDialog.devicesGroupsTable.confirm2Text')}<br/>
        ${this.$t('locationPage.deviceGroupsDialog.devicesGroupsTable.confirm3Text')}
    `

    if (await this.confirm({ title, text })) {
      try {
        this.isLoading = true

        this.destroyGroup(await DeviceGroupService.destroy(id))

        this.setNotification({ text: this.$t('locationPage.deviceGroupsDialog.devicesGroupsTable.deviceNotificationText').toString() })
      } catch {} finally {
        this.isLoading = false
      }
    }
  }

  private async mounted(): Promise<void> {
    try {
      this.isLoading = true

      this.setGroups(await DeviceGroupService.fetch())
    } catch {} finally {
      this.isLoading = false
    }
  }
}
