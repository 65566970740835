import { Component, Watch, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import L, { latLng, icon, Icon, divIcon, DivIcon } from 'leaflet'
import { LMap, LTileLayer, LPolyline, LTooltip, LMarker, LPopup, LControlZoom, LCircle, LPolygon } from 'vue2-leaflet'
// @ts-ignore
import LMovingRotatedMarker from 'vue2-leaflet-moving-rotated-marker'
import DevicesGroupsDialog from './DevicesGroupsDialog/Devices-Groups-Dialog.vue'
import DeviceSensorsInfoDialog from './DevicesList/DeviceSensorsInfoDialog/Device-Sensors-Info-Dialog.vue'
import DeviceCommandsDialog from './DevicesList/DeviceCommandsDialog/Device-Commands-Dialog.vue'
import DeviceInfo from './DeviceInfo/Device-Info.vue'
import DeviceCard from './DevicesList/DeviceCard/Device-Card.vue'
import DevicesList from './DevicesList/Devices-List.vue'
import DeviceDialog from './DevicesList/DeviceDialog/Device-Dialog.vue'
import { DeviceResponse, DeviceSensorItem } from '@/App/Services/interfaces/device.interface'
import { Polyline, SelectedDevice } from '@/store/interfaces/device.interfaces'
import { StopItem, TripPointItem } from '@/App/Pages/Location/DeviceInfo/DeviceInfo'
import { getLocationAddress } from '@/App/Utils/utils'
import { FollowDeviceItem, MapDeviceItem } from '@/store/modules/location.store'
import { Geofence } from '@/store/interfaces/geofence.interfaces'
import { DeviceService } from '@/App/Services/Device.service'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'

const deviceStore = namespace('Device')
const locationStore = namespace('Location')
const geofenceStore = namespace('Geofence')
const sharedStore = namespace('Shared')

@Component({
  components: {
    LMap,
    LTileLayer,
    LPolyline,
    LTooltip,
    LMarker,
    LPopup,
    LControlZoom,
    LCircle,
    LPolygon,
    DevicesGroupsDialog,
    LMovingRotatedMarker,
    DeviceInfo,
    DevicesList,
    DeviceCard,
    DeviceDialog,
    DeviceSensorsInfoDialog,
    DeviceCommandsDialog
  }
})
export default class LocationsPage extends Vue {
  @Prop({ required: true })
  public readonly icon: string

  @Prop({ required: true })
  public readonly deviceName: string

  @Prop({ required: true, default: [] })
  public readonly sensors: DeviceSensorItem[]

  public $refs: {
    map: HTMLFormElement
  }

  @locationStore.State
  public mapDevices: MapDeviceItem[]

  @locationStore.Mutation
  private readonly toggleMapDevice: (device: MapDeviceItem) => void

  @locationStore.Mutation
  private readonly setMapCenter: (center: [number, number]) => void

  @locationStore.Mutation
  private setFollowDevices: (device: FollowDeviceItem | null) => void

  @locationStore.Mutation
  private readonly destroyDevice: (id: string) => void

  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  @locationStore.State
  private readonly devices: DeviceResponse[]

  @deviceStore.State
  public selectedDevices: SelectedDevice[]

  @locationStore.State
  private readonly deviceFollow: FollowDeviceItem | null

  @locationStore.State
  private readonly mapCenter: [number, number]

  @deviceStore.Mutation
  private clearSelectedDevice: () => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @geofenceStore.State
  private geofences: Geofence[]

  public zoom = 2
  public center = [0, 0]
  public selectedDeviceId: string | null = null
  public polyline: Polyline = []
  public tripEmulationMarkerPosition: [number, number] = [0, 0]
  public tripEmulationStatus = false
  public tripEmulationPolyline: Polyline = []
  public stopMarkers: StopMarker[] = []
  public commonMarker: CommonMarker | null = null
  public tripsPointsInfoMarkers: HTMLMarker[] | null = null

  @Watch('mapDevices')
  public mapDevicesChanged(val: MapDeviceItem[]): void {
    if (this.deviceFollow) {
      return
    }

    if (!val.length) {
      this.zoom = 2
    }
  }

  @Watch('mapCenter')
  public mapCenterChanged(val: [number, number]): void {
    if (val[0] === 0 && val[1] === 0) {
      return
    }

    this.zoom = 14
    // For avoid bug with incorrect zooming wrong position
    setTimeout(() => {
      this.center = val
    }, 500)
  }

  @Watch('deviceFollow')
  public deviceFollowChanged(val: FollowDeviceItem | null): void {
    if (!val && this.mapDevices.length) {
      this.zoom = 14
      // For avoid bug with incorrect zooming wrong position
      setTimeout(() => {
        const { lat, lon } = this.mapDevices[this.mapDevices.length - 1].position
        this.center = [lat, lon]
      }, 500)
    }

    if (!val && !this.mapDevices.length) {
      this.zoom = 2
    }

    if (val) {
      this.zoom = 14
      // For avoid bug with incorrect zooming wrong position
      setTimeout(() => {
        this.center = [val.position.lat, val.position.lon]
      }, 500)
    }
  }

  public centeredMarker(position: [number, number]): void {
    this.center = position
    this.$refs.map.mapObject.setView(latLng(position[0], position[1]), 14)
  }

  public buildTrack(tripsPoints: TripPointItem[]): void {
    this.polyline = tripsPoints.map(tp => tp.center)
    this.$refs.map.mapObject.fitBounds(this.polyline)

    this.tripsPointsInfoMarkers = tripsPoints.map(tp => ({
      icon: divIcon({
        html: '<div></div>',
        className: 'trip-point-info-marker'
      }),
      center: tp.center,
      speed: tp.speed,
      time: tp.time
    }))
  }

  public stopTripEmulation(): void {
    this.tripEmulationStatus = false
    this.tripEmulationMarkerPosition = [0, 0]
    this.tripEmulationPolyline = []
  }

  public startTripEmulation(polyline: Polyline): void {
    this.tripEmulationStatus = true

    this.tripEmulation(polyline)
  }

  public async tripEmulation(polyline: Polyline): Promise<void> {
    this.tripEmulationPolyline = []
    for (const p of polyline) {
      if (!this.tripEmulationStatus) {
        return
      }

      await new Promise(r => {
        this.tripEmulationMarkerPosition = p
        this.tripEmulationPolyline.push(p)
        setTimeout(() => r(), 100)
      })
    }

    this.tripEmulation(polyline)
  }

  public stopsChanged(stops: StopItem[]) {
    stops.forEach(s =>
      this.stopMarkers.push({
        icon: icon({
          iconUrl: require('@/assets/mapIcons/stop.png'),
          iconSize: [20, 20]
        }),
        center: [s.position[0], s.position[1]],
        start: s.start,
        end: s.end,
        duration: s.end - s.start,
        address: ''
      })
    )
  }

  public async setStopPointAddress(idx: number): Promise<void> {
    if (!this.stopMarkers[idx].address) {
      this.stopMarkers[idx].address =
        await getLocationAddress(this.stopMarkers[idx].center[0], this.stopMarkers[idx].center[1])
    }
  }

  public toggleTripPointMarker(payload?: CommonMarkerPayload): void {
    if (!payload) {
      this.commonMarker = null
      return
    }
    const { position, iconName } = payload
    if (this.commonMarker?.center[0] === position[0] && this.commonMarker?.center[1] === position[1]) {
      this.commonMarker = null
    } else {
      this.commonMarker = {
        icon: icon({
          iconUrl: require(`@/assets/mapIcons/${iconName}`),
          iconSize: [35, 35]
        }),
        center: position
      }
      this.$refs.map.mapObject.setView(latLng(position[0], position[1]), 16)
    }
  }

  public async removeDevice(id: string): Promise<void> {
    const title = this.$t('locationPage.devicesList.deleteDeviceConfirmTitle').toString()
    const text = `
        ${this.$t('locationPage.devicesList.deleteDeviceConfirmText1')}<br/>
        ${this.$t('locationPage.devicesList.deleteDeviceConfirmText2')}<br/>
        ${this.$t('locationPage.devicesList.deleteDeviceConfirmText3')}
    `

    if (await this.confirm({ title, text })) {
      try {
        this.destroyDevice(await DeviceService.destroy(id))

        this.setNotification({ text: this.$t('locationPage.devicesList.deviceDeletedNotificationText').toString() })
      } catch (e) {
        console.log(e)
      }
    }
  }

  public clearTrack(): void {
    this.polyline = []
    this.stopMarkers = []
    this.tripsPointsInfoMarkers = []
  }

  public closeDeviceInfo(): void {
    this.selectedDeviceId = null
    this.clearTrack()
  }

  private destroyed() {
    this.clearSelectedDevice()
  }

  public followDevice({ _id, sensors, name, icon }: DeviceResponse): void {
    if (this.deviceFollow && this.deviceFollow.name === name) {
      this.setFollowDevices(null)
    } else {
      const { latitude, longitude, direction } = JSON.parse(sensors[0].lastValue)
      this.setFollowDevices({
        deviceId: _id,
        name,
        icon,
        direction,
        position: {
          lat: latitude,
          lon: longitude
        }
      })
    }
  }

  public centerDevice(id: string, val: string): void {
    if (this.mapDevices.find(d => d.id === id)) {
      const { latitude, longitude } = JSON.parse(val)

      this.$emit('centeredMarker', [latitude, longitude])
    }
  }

  public toggleDeviceOnMap(id: string): void {
    const { deviceId, icon, sensors, name } = this.devices.find(d => d._id === id)!
    const locationData = JSON.parse(sensors[0].lastValue)
    this.toggleMapDevice({
      id,
      deviceId: +deviceId,
      positionSensorId: sensors[0]._id,
      name,
      icon: L.icon({
        iconUrl: require(`@/assets/mapIcons/${icon}`),
        iconSize: [35, 35]
      }),
      direction: locationData.direction,
      position: {
        lat: locationData.latitude,
        lon: locationData.longitude
      }
    })
    this.setMapCenter([locationData.latitude, locationData.longitude])
  }

  public deviceInfo(deviceId: string): DeviceResponse {
    return this.devices.find(d => d._id === deviceId)!
  }

  get followDeviceMarker(): L.Icon | null {
    if (!this.deviceFollow) {
      return null
    }

    return L.icon({
      iconUrl: require(`@/assets/mapIcons/${this.deviceFollow.icon}`),
      iconSize: [35, 35]
    })
  }

  public deviceData(id: string): any {
    const device = this.devices.find(d => d._id === id)
    if (device) {
      return device
    }
  }

  get circleActiveGeofences(): Geofence[] {
    return this.geofences.filter(g => g.type === 'circle' && g.isShowOnMap)
  }

  get polygonActiveGeofences(): Geofence[] {
    return this.geofences.filter(g => g.type === 'polygon' && g.isShowOnMap)
  }
}

interface CommonMarkerPayload {
  position: [number, number]
  iconName: string
}

interface HTMLMarker {
  icon: DivIcon;
  center: [number, number];
  speed: number;
  time: number;
}

interface CommonMarker {
  icon: Icon,
  center: [number, number]
}

interface StopMarker extends CommonMarker{
  start: number
  end: number
  duration: number
  address: string
}
