import { render, staticRenderFns } from "./Devices-List.vue?vue&type=template&id=08a21832&scoped=true&"
import script from "./DevicesList.ts?vue&type=script&lang=ts&"
export * from "./DevicesList.ts?vue&type=script&lang=ts&"
import style0 from "./DevicesList.scss?vue&type=style&index=0&id=08a21832&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a21832",
  null
  
)

export default component.exports