import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TripCard extends Vue {
  @Prop({ required: true })
  public begin: number

  @Prop({ required: true })
  public end: number

  @Prop({ required: true })
  public duration: number

  @Prop({ required: true })
  public distance: string | number

  @Prop({ required: true })
  public speedMax: number

  @Prop({ required: true })
  public speedAverage: number

  public tripEmulationBtnIcon = 'fa fa-play'
  public tripEmulationBtnTooltip = this.$t('locationPage.deviceInfo.tripCard.startTripEmulationTooltipText').toString()

  public buildTrack(): void {
    this.$emit('buildTrack')
  }

  public toggleTripEmulationStatus(): void {
    this.tripEmulationBtnIcon = this.tripEmulationBtnIcon === 'fa fa-stop' ? 'fa fa-play' : 'fa fa-stop'
    this.tripEmulationBtnTooltip =
      this.tripEmulationBtnIcon === 'fa fa-stop'
        ? this.$t('locationPage.deviceInfo.tripCard.stopTripEmulationTooltipText').toString()
        : this.$t('locationPage.deviceInfo.tripCard.startTripEmulationTooltipText').toString()

    this.$emit('toggleTripEmulationStatus')
  }

  public showTripPoint(type: 'start' | 'finish'): void {
    this.$emit('toggleTripPointMarker', type )
  }
}
