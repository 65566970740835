import { HTTP } from '@/App/Utils/axios'
import { DeviceGroupResponse, DeviceGroupRequest } from '@/App/Services/interfaces/deviceGroup.interface'

export class DeviceGroupService {
  static async fetch(): Promise<DeviceGroupResponse[]> {
    try {
      const { data } = await HTTP.get('/devices-groups')

      return data
    } catch (e) {
      throw e
    }
  }

  static async store(group: DeviceGroupRequest): Promise<DeviceGroupResponse> {
    try {
      const { data } = await HTTP.post('/devices-groups', group)

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(id: string, group: DeviceGroupRequest): Promise<DeviceGroupResponse> {
    try {
      const { data } = await HTTP.put(`/devices-groups/${id}`, group)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroy(id: string): Promise<string> {
    try {
      const { data } = await HTTP.delete(`/devices-groups/${id}`)

      return data
    } catch (e) {
      throw e
    }
  }
}
