import { HTTP } from '@/App/Utils/axios'
import { DeviceResponse, DeviceRequest, TrackPointsRequest, DeviceCommandItem } from '@/App/Services/interfaces/device.interface'
import { Polyline } from '@/store/interfaces/device.interfaces'

export class DeviceService {
  static async fetch(): Promise<DeviceResponse[]> {
    try {
      const { data } = await HTTP.get('/devices')

      return data
    } catch (e) {
      throw e
    }
  }

  static async store(device: DeviceRequest): Promise<DeviceResponse> {
    try {
      const { data } = await HTTP.post('/devices/', device)

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(id: string, device: DeviceRequest): Promise<DeviceResponse> {
    try {
      const { data } = await HTTP.put(`/devices/${id}`, device)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroy(id: string): Promise<string> {
    try {
      const { data } = await HTTP.delete(`/devices/${id}`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchTrackPoints({ deviceId, ...payload }: TrackPointsRequest): Promise<Polyline> {
    try {
      const { data } = await HTTP.get(`/devices/${deviceId}/track-points`, {
        params: {
          ...payload
        }
      })

      return data
    } catch (e) {
      throw e
    }
  }

  static async storeDeviceCommand(deviceId: string, command: StoreCommand): Promise<DeviceCommandItem> {
    try {
      const { data } = await HTTP.post(`/devices/${deviceId}/command`, command)

      return data
    } catch (e) {
      throw e
    }
  }

  static async updateDeviceCommand(deviceId: string, commandId: string, command: StoreCommand): Promise<DeviceCommandItem> {
    try {
      const { data } = await HTTP.put(`/devices/${deviceId}/command/${commandId}`, command)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroyDeviceCommand(deviceId: string, commandId: string): Promise<void> {
    try {
      await HTTP.delete(`/devices/${deviceId}/command/${commandId}`)
    } catch (e) {
      throw e
    }
  }
}

interface StoreCommand {
  name: string
  payload?: string
}
