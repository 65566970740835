import { render, staticRenderFns } from "./Locations-Page.vue?vue&type=template&id=10675919&scoped=true&"
import script from "./LocationsPage.ts?vue&type=script&lang=ts&"
export * from "./LocationsPage.ts?vue&type=script&lang=ts&"
import style0 from "./LocationsPage.scss?vue&type=style&index=0&id=10675919&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10675919",
  null
  
)

export default component.exports