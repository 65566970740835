import { Component, Prop, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import { FlespiService } from '@/App/Services/Flespi.service'
import { DateTimeInterval, IntervalPicker } from '@/App/Components/Pickers'

@Component({
  components: { IntervalPicker }
})
export default class TripIntervalSelect extends Vue {
  $refs: {
    intervalPicker: HTMLFormElement
  }

  @Prop({ required: true })
  public flespiDeviceId: number

  public async trackIntervalSelected(interval: 'today' | 'yesterday' | 'custom'): Promise<void> {
    try {
      const params = {
        from: 0,
        to: 0
      }

      switch (interval) {
        case 'today':
          params.from = dayjs().startOf('day').unix()
          params.to = dayjs().unix()
          break
        case 'yesterday':
          params.from = dayjs().subtract(1, 'day').startOf('day').unix()
          params.to = dayjs().subtract(1, 'day').endOf('day').unix()
          break
        case 'custom':
          const data = await this.$refs.intervalPicker.open(true) as DateTimeInterval | false

          if (!data) {
            return
          }

          params.from = data.from
          params.to = data.to
          break
      }

      this.$emit('loadingChanged', true)

      const trips = await FlespiService.fetchDeviceTrips(this.flespiDeviceId, params)
      this.$emit('tripDataFetched', trips)
    } catch {} finally {
      this.$emit('loadingChanged', false)
    }
  }
}
