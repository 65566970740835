import { Component, Mixins } from 'vue-property-decorator'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import DeviceCommandResponseDialog from './DeviceCommandResponseDialog/Device-Command-Response-Dialog.vue'
import { DeviceCommandRequest, FlespiService } from '@/App/Services/Flespi.service'
import { Validator } from '@/App/Mixins'
import { DeviceService } from '@/App/Services/Device.service'
import { DeviceCommandItem, DeviceResponse } from '@/App/Services/interfaces/device.interface'
import { namespace } from 'vuex-class'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const locationStore = namespace('Location')
const sharedStore = namespace('Shared')

@Component({
  components: { DeviceCommandResponseDialog }
})
export default class DeviceCommandsDialog extends Mixins(Validator) {
  $refs: {
    form: HTMLFormElement
    deviceCommandResponseDialog: HTMLFormElement
  }

  @locationStore.State
  private readonly devices: DeviceResponse[]

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  @locationStore.Mutation
  private updateDevice: (device: DeviceResponse) => void

  @sharedStore.State
  private readonly userInfo: UserInfo

  public isOpen = false
  public isLoading = false
  public search = '';
  public headers: VuetifyTableHeader[] = [
    { text: this.$t('locationPage.devicesList.deviceCommandsDialog.tableColNameText').toString(), align: 'left', sortable: true, value: 'name' },
    { text: this.$t('locationPage.devicesList.deviceCommandsDialog.tableColActionText').toString(), value: 'left', align: 'center', sortable: false }
  ]
  public commands: CommandItem[] = [];
  public form = false
  public command = {
    name: '',
    payload: ''
  }
  public isCreateMode = false
  private flespiDeviceId: number | undefined
  private deviceId: string | undefined
  public editingCommandId: string | null = null

  public async open(deviceId: string, flespiDeviceId: number, customCommand: DeviceCommandItem[]): Promise<void> {
    try {
      this.isOpen = true
      this.isLoading = true
      this.isCreateMode = false

      const deviceCommands = await FlespiService.fetchDeviceCommands(flespiDeviceId)
      // @ts-ignore
      this.commands = [
        ...customCommand.map(cc => ({ type: 'custom', name: cc.name, commandId: cc._id, payload: cc.payload })),
        ...deviceCommands.map(dc => ({ type: 'device', name: dc }))
      ]

      this.flespiDeviceId = flespiDeviceId
      this.deviceId = deviceId
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }

  public edit({ commandId, name, payload }: CommandItem): void {
    this.command = { name, payload: payload || '' }
    this.editingCommandId = commandId!
    this.isCreateMode = true
  }

  public async submit(): Promise<void> {
    try {
      this.isLoading = true

      const device = this.devices.find(d => d._id === this.deviceId)

      if (device) {
        if (this.editingCommandId) {
          const data = await DeviceService.updateDeviceCommand(this.deviceId!, this.editingCommandId, this.command)

          device.commands = device.commands.map(d => {
            if (d._id === this.editingCommandId) {
              return data
            } else {
              return d
            }
          })

          this.updateDevice(device)

          this.commands = this.commands.map(c => {
            if (c.commandId === this.editingCommandId) {
              return { type: 'custom', name: data.name, commandId: data._id, payload: data.payload }
            } else {
              return c
            }
          })

          this.setNotification({ text: this.$t('locationPage.devicesList.deviceCommandsDialog.deviceCommandUpdatedNotificationText').toString() })
        } else {
          const data = await DeviceService.storeDeviceCommand(this.deviceId!, this.command)

          device.commands.push(data)

          this.updateDevice(device)

          this.commands.push({ type: 'custom', name: data.name, commandId: data._id, payload: data.payload })

          this.setNotification({ text: this.$t('locationPage.devicesList.deviceCommandsDialog.deviceCommandCreatedNotificationText').toString() })
        }
      }

      this.isCreateMode = false
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }

  public async remove(commandId: string): Promise<void> {
    const title = this.$t('locationPage.devicesList.deviceCommandsDialog.deleteCommandConfirmTitle').toString()
    const text = this.$t('locationPage.devicesList.deviceCommandsDialog.deleteCommandConfirmText').toString()

    if (await this.confirm({ title, text })) {
      try {
        await DeviceService.destroyDeviceCommand(this.deviceId!, commandId)

        const device = this.devices.find(d => d._id === this.deviceId)

        if (device) {
          this.updateDevice({
            ...device,
            commands: device.commands.filter(s => s._id !== commandId)
          })

          this.commands = this.commands.filter(c => c.commandId !== commandId)

          this.setNotification({ text: this.$t('locationPage.devicesList.deviceCommandsDialog.deviceCommandDeletedNotificationText').toString() })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  public changeCreateMode(): void {
    if (this.commands.filter(c => c.type === 'custom').length === 6) {
      this.setNotification({ text: this.$t('locationPage.devicesList.deviceCommandsDialog.customCommandNotificationText').toString(), color: 'warning' })
    } else {
      this.editingCommandId = null
      this.isCreateMode = !this.isCreateMode

      this.command = {
        name: '',
        payload: ''
      }

      this.$refs.form.resetValidation()
    }
  }

  public async send({ name, type, payload }: CommandItem): Promise<void> {
    try {
      this.isLoading = true

      const commandData: DeviceCommandRequest = {
        name: type === 'device' ? name : 'custom'
      }

      if (type === 'custom') {
        commandData.payload = payload
      }

      const response = await FlespiService.sendDeviceCommand(this.flespiDeviceId!, commandData)

      this.$refs.deviceCommandResponseDialog.open(response)

      this.setNotification({ text: this.$t('locationPage.devicesList.deviceCommandsDialog.commandSentNotificationText').toString() })
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }
}

type CommandType = 'device' | 'custom'

interface CommandItem {
  type: CommandType
  name: string
  commandId?: string
  payload?: string
}
