import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { GeofenceService } from '@/App/Services/Geofence.service'
import { Geofence } from '@/store/interfaces/geofence.interfaces'
import { VuetifyTableHeader } from '@/interfaces/vuetify'

const geofenceStore = namespace('Geofence')

@Component
export default class DeviceDialog extends Vue {
  @geofenceStore.State
  private geofences: Geofence[]

  @geofenceStore.Mutation
  private setGeofences: (geofences: Geofence[]) => void

  @geofenceStore.Mutation
  private updateGeofence: (geofence: Partial<Geofence>) => void

  public isOpen = false
  public search = ''
  public isLoading = false
  public headers: VuetifyTableHeader[] = [
    { text: this.$t('locationPage.devicesList.geofencesDialog.tableColStatusText').toString(), align: 'left', sortable: true, value: 'status' },
    { text: this.$t('locationPage.devicesList.geofencesDialog.tableColNameText').toString(), align: 'left', sortable: true, value: 'name' }
  ]

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true
      this.setGeofences(await GeofenceService.fetch())
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  }

  public toggle(id: string): void {
    const geofenceItem = this.geofences.find(g => g._id === id)

    if (geofenceItem) {
      this.updateGeofence({ ...geofenceItem, isShowOnMap: !geofenceItem.isShowOnMap })
    }
  }
}
