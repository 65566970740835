import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import DeviceSensorForm from './DeviceSensorForm/Device-Sensor-Form.vue'
import IconSelect from './IconSelect/Icon-Select.vue'
import { DeviceService } from '@/App/Services/Device.service'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { DeviceResponse } from '@/App/Services/interfaces/device.interface'
import { Sensor } from './DeviceSensorForm/DeviceSensorForm'

const sharedStore = namespace('Shared')
const locationStore = namespace('Location')

@Component({
  components: { DeviceSelect, DeviceSensorForm, IconSelect }
})
export default class DeviceDialog extends Mixins(Validator) {
  $refs!: {
    form: HTMLFormElement
  }

  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  @locationStore.Mutation
  private addDevice: (device: DeviceResponse) => void

  @locationStore.Mutation
  private updateDevice: (device: DeviceResponse) => void

  public isOpen = false
  public isLoading = false
  public form = false
  public device: Device = {
    deviceId: '',
    name: '',
    sensors: [],
    icon: ''
  }

  private edit(val: DeviceResponse): void {
    if (val) {
      this.device = JSON.parse(JSON.stringify(val))

      this.isOpen = true
    }
  }

  public onClose(): void {
    this.device = {
      deviceId: '',
      name: '',
      sensors: [],
      icon: ''
    }

    this.$refs.form.resetValidation()

    this.isOpen = false
  }

  public async save(): Promise<void> {
    try {
      this.isLoading = true

      this.addDevice(await DeviceService.store(this.device))

      this.setNotification({ text: this.$t('locationPage.devicesList.deviceDialog.deviceCreatedNotificationText').toString() })

      this.onClose()
    } catch (e) {} finally {
      this.isLoading = false
    }
  }

  public async update(): Promise<void> {
    try {
      this.isLoading = true
      this.updateDevice(await DeviceService.update(this.device._id || '', this.device))

      this.setNotification({ text: this.$t('locationPage.devicesList.deviceDialog.deviceUpdatedNotificationText').toString() })

      this.onClose()
    } catch (e) {} finally {
      this.isLoading = false
    }
  }

  public onDeviceSensorChanged(sensor: Sensor): void {
    if (sensor._id) {
      const idx = this.device.sensors.findIndex(s => s._id === sensor._id)

      this.device.sensors[idx] = sensor
    }

    if (sensor.customId) {
      const idx = this.device.sensors.findIndex(s => s.customId === sensor.customId)

      this.device.sensors[idx] = sensor
    }
  }

  public onDeviceSensorRemove(_id: string): void {
    this.device.sensors = this.device.sensors.filter(s => (s._id !== _id && s._id) || (s.customId !== _id && s.customId))
  }

  public onDeviceSensorAdd(): void {
    this.device.sensors.push({
      customId: this.device.sensors.length.toString(),
      sensorName: '',
      name: '',
      mathExpression: ''
    })
  }
}

interface Device {
  _id?: string
  deviceId: string
  name: string
  sensors: Sensor[]
  icon: string
}
