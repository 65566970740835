import { Component, Vue } from 'vue-property-decorator'
import { DeviceSensorItem } from '@/App/Services/interfaces/device.interface'

@Component
export default class DeviceSensorsInfoDialog extends Vue {
  $refs!: {
    form: HTMLFormElement
  }

  public isOpen = false
  public deviceName = ''
  public sensorsItems: SensorItem[] = [];

  public open(deviceName: string, sensors: DeviceSensorItem[]): void {
    this.isOpen = true
    this.deviceName = deviceName
    this.sensorsItems = []

    sensors.forEach(({ name, lastValue }, idx) => {
      if (idx === 0) {
        const { latitude, longitude, satellites } = JSON.parse(lastValue)
        this.sensorsItems.push(
          { name: this.$t('locationPage.devicesList.deviceSensorsInfoDialog.positionName').toString(), value: `${latitude} ${longitude}` },
          { name: this.$t('locationPage.devicesList.deviceSensorsInfoDialog.satellitesName').toString(), value: satellites }
        )
      } else {
        this.sensorsItems.push({ name, value: lastValue })
      }
    })
  }
}

interface SensorItem {
  name: string
  value: string
}
