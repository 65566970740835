import { Component, Vue } from 'vue-property-decorator'
import { DeviceCommandResponse } from '@/App/Services/Flespi.service'

@Component
export default class DeviceCommandResponseDialog extends Vue {
  public isOpen = false
  public response: DeviceCommandResponse | null = null

  public open(response: DeviceCommandResponse): void {
    this.isOpen = true

    this.response = response
  }
}
