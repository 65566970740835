import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import CircularLoader from '@/App/Components/CircularLoader/Circular-Loader.vue'
import TripCard from './TripCard/Trip-Card.vue'
import TripIntervalSelect from './TripIntervalSelect/Trip-Interval-Select.vue'
import { DeviceResponse } from '@/App/Services/interfaces/device.interface'
import { DeviceTripItem } from '@/App/Services/interfaces/flespi.interface'

const locationStore = namespace('Location')

@Component({
  components: { CircularLoader, TripCard, TripIntervalSelect }
})
export default class DeviceInfo extends Vue {
  @Prop({ required: true })
  private deviceId: string | null

  @locationStore.State
  public originDevices: DeviceResponse[]

  public isLoading = false
  private flespiDeviceId = 0
  public deviceName = ''
  public trips: DeviceTripItem[] = []
  public tripEmulationIdx: number | null = null
  public totalDistance = 0
  public isFullTripEmulationStart = false

  @Watch('deviceId')
  private deviceIdChanged(id: string | null): void {
    if (id) {
      this.reset()
      const device = this.originDevices.find(d => d.deviceId === this.deviceId)
      if (device) {
        this.deviceName = device.name
        this.flespiDeviceId = +device.deviceId
      }
    }
  }

  public reset(): void {
    this.trips = []
    this.isFullTripEmulationStart = false
    this.totalDistance = 0
    this.$emit('finishTripEmulation')
    this.$emit('toggleTripPointMarker')
    this.clearTrack()
  }

  public centeredMarker(position: [number, number]): void {
    this.$emit('centeredMarker', position)
  }

  public loadingChanged(status: boolean): void {
    this.isLoading = status
  }

  public tripDataFetched(trips: DeviceTripItem[]): void {
    this.totalDistance = 0
    this.trips = trips
  }

  public buildTrack(idx: number): void {
    this.totalDistance = 0
    this.$emit('buildTrack', this.getTripPoints(idx))
  }

  public toggleTripEmulationStatus(idx: number): void {
    this.tripEmulationIdx = this.tripEmulationIdx === idx ? null : idx

    if (this.tripEmulationIdx !== null) {
      this.$emit('startTripEmulation', this.getTripPoints(idx).map(tp => tp.center))
    } else {
      this.$emit('finishTripEmulation')
    }
  }

  public toggleFullTripEmulationStatus(): void {
    this.isFullTripEmulationStart = !this.isFullTripEmulationStart

    if (this.isFullTripEmulationStart) {
      const points: [number, number][] = []
      this.trips.forEach(t => {
        t['track.positions'].forEach(p => points.push([p['position.latitude'], p['position.longitude']]))
      })
      this.$emit('startTripEmulation', points)
    } else {
      this.$emit('finishTripEmulation')
    }
  }

  public toggleTripPointMarker(type: 'start' | 'finish', idx: number): void {
    const payload: { iconName: string; position: [number, number] } = {
      iconName: `${type}.png`,
      position: [0, 0]
    }
    if (type === 'start') {
      payload.position = [
        this.trips[idx]['track.positions'][0]['position.latitude'],
        this.trips[idx]['track.positions'][0]['position.longitude']
      ]
    } else {
      payload.position = [
        this.trips[idx]['track.positions'][this.trips[idx]['track.positions'].length - 1]['position.latitude'],
        this.trips[idx]['track.positions'][this.trips[idx]['track.positions'].length - 1]['position.longitude']
      ]
    }


    this.$emit('toggleTripPointMarker', payload)
  }

  private getTripPoints(idx: number): TripPointItem[] {
    const points: TripPointItem[] = []
    this.trips[idx]['track.positions'].forEach(p => points.push({
      center: [p['position.latitude'], p['position.longitude']],
      speed: p['position.speed'],
      time: p['tm']
    }))

    return points
  }

  public showFullTrack(): void {
    this.totalDistance = 0
    const points: TripPointItem[] = []
    const stops: StopItem[] = []
    this.trips.forEach((p, idx) => {
      this.totalDistance = this.totalDistance + p.distance
      if (this.trips.length > idx + 1) {
        stops.push({
          position: [
            p['track.positions'][p['track.positions'].length - 1]['position.latitude'],
            p['track.positions'][p['track.positions'].length - 1]['position.longitude']
          ],
          start: p.end,
          end: this.trips[idx + 1].begin
        })
      }
      p['track.positions'].forEach(t => points.push({
        center: [t['position.latitude'], t['position.longitude']],
        speed: t['position.speed'],
        time: t['tm']
      }))
    })
    this.$emit('stopsChanged', stops)
    this.$emit('buildTrack', points)

    this.totalDistance = +this.totalDistance.toFixed(2)
  }

  public close(): void {
    this.$emit('close')

    this.reset()
  }

  public clearTrack(): void {
    this.$emit('clearTrack')
  }

  get isFullTrackBtnShow(): boolean {
    return !!this.trips.length
  }

  get fullTripEmulationIcon(): string {
    return this.isFullTripEmulationStart ? 'fa fa-stop' : 'fa fa-play'
  }

  get fullTripEmulationTooltip(): string {
    return this.isFullTripEmulationStart
      ? this.$t('locationPage.deviceInfo.stopTripEmulationTooltipText').toString()
      : this.$t('locationPage.deviceInfo.startTripEmulationTooltipText').toString()
  }
}

export interface StopItem {
  position: [number, number],
  start: number
  end: number
}

export interface TripPointItem {
  center: [number, number];
  speed: number;
  time: number;
}
