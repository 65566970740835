import { Component, Vue, Prop } from 'vue-property-decorator'
import { DeviceSensorItem } from '@/App/Services/interfaces/device.interface'
import { namespace } from 'vuex-class'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component
export default class DeviceCard extends Vue {
  @Prop({ required: true })
  public readonly icon: string

  @Prop({ required: true })
  public readonly deviceName: string

  @Prop({ required: true, default: [] })
  public readonly sensors: DeviceSensorItem[]


  @sharedStore.State
  private readonly userInfo: UserInfo

  public menuItems: MenuItem[] = [
    {
      text: this.$t('locationPage.devicesList.deviceCard.menuItemFollowOnMapText').toString(),
      icon: 'fa fa-location-arrow',
      color: '949494',
      action: () => this.$emit('followDevice')
    },
    {
      text: this.$t('locationPage.devicesList.deviceCard.menuItemSensorInfoText').toString(),
      icon: 'fa fa-microchip',
      color: '949494',
      action: () => this.$emit('showSensorsInfo')
    },
    {
      text: this.$t('locationPage.devicesList.deviceCard.menuItemTripsInfoText').toString(),
      icon: 'fa fa-route',
      color: '949494',
      action: () => this.$emit('showTripInfo')
    },
    {
      text: this.$t('locationPage.devicesList.deviceCard.menuItemCommandsText').toString(),
      icon: 'fa fa-paper-plane',
      color: '949494',
      action: () => this.$emit('showCommands')
    }
  ]
  // Vue not react on changed sensors props this is workaround try fix this later
  public localSensors: DeviceSensorItem[] = []
  public timeout: number
  public isOpen = false

  public async openCard(): Promise<void> {
    this.isOpen = true
  }

  // Vue not react on changed sensors props this is workaround try fix this later
  public created(): void {
    this.localSensors = [...this.sensors]
    this.timeout = setInterval(() => this.localSensors = [...this.sensors], 5000)

    if (this.userInfo.role === 'admin') {
      this.menuItems.push(
        { text: this.$t('locationPage.devicesList.deviceCard.menuItemEditText').toString(), icon: 'fa fa-pen', color: '949494', action: () => this.$emit('editDevice') },
        { text: this.$t('locationPage.devicesList.deviceCard.menuItemDeleteText').toString(), icon: 'fa fa-trash', color: 'red', action: () => this.$emit('removeDevice') }
      )
    }
  }

  public destroyed() {
    clearInterval(this.timeout)
  }


  get isDeviceOnline(): boolean {
    if (!this.localSensors[0].lastUpdateTime) {
      return false
    }

    return this.localSensors[0].lastUpdateTime > (Date.now() - 36e5) / 1000
  }

  get deviceIcon(): string {
    return require(`@/assets/mapIcons/${this.icon}`)
  }

  get speedSensorValue(): string {
    if (this.localSensors[0].lastValue) {
      const locationData = JSON.parse(this.localSensors[0].lastValue)

      return `${locationData.speed} km/h`
    }

    return 'Unknown'
  }

  get addressSensorValue(): string {
    if (this.localSensors[0].lastValue) {
      const locationData = JSON.parse(this.localSensors[0].lastValue)
      return `${locationData.latitude} ${locationData.longitude}`
    }

    return 'Unknown'
  }

}

interface MenuItem {
  text: string;
  icon: string;
  color: string;
  action: () => void;
}
