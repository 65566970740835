import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import UserSelect from '@/App/Components/UserSelect/User-Select.vue'
import DeviceSelect from './DeviceSelect/Device-Select.vue'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { DeviceGroupService } from '@/App/Services/DeviceGroup.service'
import { DeviceGroupResponse } from '@/App/Services/interfaces/deviceGroup.interface'

const sharedStore = namespace('Shared')
const deviceGroupStore = namespace('DeviceGroup')

@Component({
  components: { UserSelect, DeviceSelect }
})
export default class DeviceGroupDialog extends Mixins(Validator) {
  $refs!: {
    form: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @deviceGroupStore.State
  private readonly editingGroup: DeviceGroupResponse

  @deviceGroupStore.Mutation
  private readonly editGroup: (group: DeviceGroupResponse | null) => void

  @deviceGroupStore.Mutation
  private readonly addGroup: (group: DeviceGroupResponse) => void

  @deviceGroupStore.Mutation
  private readonly updateGroup: (group: DeviceGroupResponse) => void

  public isOpen = false
  public isLoading = false
  public form = false
  public group: DeviceGroup = {
    name: '',
    sharedUsers: [],
    devices: []
  }

  @Watch('editingGroup')
  private onEdit(val: DeviceGroupResponse): void {
    if (val) {
      this.group = val

      this.isOpen = true
    }
  }

  public onClose(): void {
    this.group = {
      name: '',
      sharedUsers: [],
      devices: []
    }

    this.$refs.form.resetValidation()

    if (this.editingGroup) {
      this.editGroup(null)
    }

    this.isOpen = false
  }

  public async onSave(): Promise<void> {
    try {
      this.isLoading = true

      this.addGroup(await DeviceGroupService.store(this.group))

      this.setNotification({ text: this.$t('locationPage.deviceGroupsDialog.deviceGroupDialog.groupCreatedNotificationText').toString() })

      this.onClose()
    } catch (e) {} finally {
      this.isLoading = false
    }
  }

  public async onUpdate(): Promise<void> {
    try {
      this.isLoading = true

      this.updateGroup(await DeviceGroupService.update(this.group._id || '', this.group))

      this.setNotification({ text: this.$t('locationPage.deviceGroupsDialog.deviceGroupDialog.groupUpdatedNotificationText').toString() })

      this.onClose()
    } catch (e) {} finally {
      this.isLoading = false
    }
  }
}

interface DeviceGroup {
  _id?: string
  name: string
  sharedUsers: string[]
  devices: string[]
}
