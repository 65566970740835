import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { VuetifySelect } from '@/interfaces/vuetify'
import { DeviceResponse } from '@/App/Services/interfaces/device.interface'

const locationStore = namespace('Location')

@Component
export default class DeviceSelect extends Vue {
  @locationStore.State
  private readonly devices: DeviceResponse[]

  @Prop({ default: '' })
  private readonly value: string

  public devicesIds: string[] = []
  public deviceItems: VuetifySelect[] = []

  @Watch('value')
  private valueChanged(val: string[]): void {
    this.devicesIds = val

    this.fillData()
  }
  // TODO think about refactoring logic now on click we fetch this data all time
  public fillData(): void {
    this.deviceItems = this.devices.map(d => {
      return { value: d._id, text: d.name }
    })
  }
}
