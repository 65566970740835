import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import L from 'leaflet'
import DeviceCard from './DeviceCard/Device-Card.vue'
import DeviceDialog from './DeviceDialog/Device-Dialog.vue'
import DeviceSensorsInfoDialog from './DeviceSensorsInfoDialog/Device-Sensors-Info-Dialog.vue'
import DeviceCommandsDialog from './DeviceCommandsDialog/Device-Commands-Dialog.vue'
import GeofencesDialog from './GeofencesDialog/Geofences-Dialog.vue'
import { DeviceService } from '@/App/Services/Device.service'
import { DeviceResponse } from '@/App/Services/interfaces/device.interface'
import { FollowDeviceItem, MapDeviceItem, Stage } from '@/store/modules/location.store'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const locationStore = namespace('Location')
const sharedStore = namespace('Shared')

@Component({
  components: { DeviceCard, DeviceDialog, DeviceSensorsInfoDialog, DeviceCommandsDialog, GeofencesDialog }
})
export default class DevicesList extends Vue {
  @locationStore.State
  private readonly devices: DeviceResponse[]

  @locationStore.State
  private readonly deviceFollow: FollowDeviceItem | null

  @sharedStore.State
  private readonly userInfo: UserInfo

  @locationStore.State
  public mapDevices: MapDeviceItem[]

  @locationStore.Mutation
  private readonly toggleMapDevice: (device: MapDeviceItem) => void

  @locationStore.Mutation
  private readonly toggleAllMapDevice: (stage: Stage) => void

  @locationStore.Mutation
  private readonly setDevices: (devices: DeviceResponse[]) => void

  @locationStore.Mutation
  private readonly destroyDevice: (id: string) => void

  @locationStore.Mutation
  private setFollowDevices: (device: FollowDeviceItem | null) => void

  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  @locationStore.Mutation
  private readonly searchDevice: (text: string) => void

  @locationStore.Mutation
  private readonly setMapCenter: (center: [number, number]) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  public isMinimized = false
  public isSelectedAllDevices = false
  public isLoading = false
  public search = '';

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true
      this.setDevices(await DeviceService.fetch())
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  }

  public toggleDeviceOnMap(id: string): void {
    const { deviceId, icon, sensors, name } = this.devices.find(d => d._id === id)!
    const locationData = JSON.parse(sensors[0].lastValue)
    this.toggleMapDevice({
      id,
      deviceId: +deviceId,
      positionSensorId: sensors[0]._id,
      name,
      icon: L.icon({
        iconUrl: require(`@/assets/mapIcons/${icon}`),
        iconSize: [35, 35]
      }),
      direction: locationData.direction,
      position: {
        lat: locationData.latitude,
        lon: locationData.longitude
      }
    })
    this.setMapCenter([locationData.latitude, locationData.longitude])
  }

  public followDevice({ _id, sensors, name, icon }: DeviceResponse): void {
    if (this.deviceFollow && this.deviceFollow.name === name) {
      this.setFollowDevices(null)
    } else {
      const { latitude, longitude, direction } = JSON.parse(sensors[0].lastValue)
      this.setFollowDevices({
        deviceId: _id,
        name,
        icon,
        direction,
        position: {
          lat: latitude,
          lon: longitude
        }
      })
    }
  }

  public async removeDevice(id: string): Promise<void> {
    const title = this.$t('locationPage.devicesList.deleteDeviceConfirmTitle').toString()
    const text = `
        ${this.$t('locationPage.devicesList.deleteDeviceConfirmText1')}<br/>
        ${this.$t('locationPage.devicesList.deleteDeviceConfirmText2')}<br/>
        ${this.$t('locationPage.devicesList.deleteDeviceConfirmText3')}
    `

    if (await this.confirm({ title, text })) {
      try {
        this.destroyDevice(await DeviceService.destroy(id))

        this.setNotification({ text: this.$t('locationPage.devicesList.deviceDeletedNotificationText').toString() })
      } catch (e) {
        console.log(e)
      }
    }
  }

  public toggleAllDevices(): void {
    this.isSelectedAllDevices = !this.isSelectedAllDevices
    this.toggleAllMapDevice(this.isSelectedAllDevices ? 'fill' : 'clear')
  }

  public centerDevice(id: string, val: string): void {
    if (this.mapDevices.find(d => d.id === id)) {
      const { latitude, longitude } = JSON.parse(val)

      this.$emit('centeredMarker', [latitude, longitude])
    }
  }

}
