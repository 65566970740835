import { Component, Vue } from 'vue-property-decorator'
import { ICONS } from './icons'

@Component
export default class IconSelect extends Vue {
  public selectedIcon = 'CarRed.svg'
  public icons = ICONS

  private mounted(): void {
    this.$emit('input', this.selectedIcon)
  }

  public onSelect(icon: string): void {
    this.selectedIcon = icon

    this.$emit('input', icon)
  }
}
