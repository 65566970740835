import { Component, Vue } from 'vue-property-decorator'
import DeviceGroupDialog from './DeviceGroupDialog/Device-Group-Dialog.vue'
import DevicesGroupsTable from './DevicesGroupsTable/Devices-Group-Table.vue'

@Component({
  components: { DeviceGroupDialog, DevicesGroupsTable }
})
export default class DevicesDialog extends Vue {
  public isOpen = false
}
