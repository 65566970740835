import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'
import SensorSelect from '@/App/Components/SensorSelect/Sensor-Select.vue'

@Component({
  components: { SensorSelect }
})
export default class DeviceSensorForm extends Mixins(Validator) {
  @Prop({ required: true })
  readonly deviceId: string

  @Prop({ default: null })
  readonly sensorOpt: Sensor

  public sensor: Sensor = {
    sensorName: '',
    name: '',
    mathExpression: ''
  }

  @Watch('sensor', { deep: true })
  private sensorDataChanged(val: Sensor): void {
    this.$emit('input', val)
  }

  private mounted(): void {
    this.sensor = this.sensorOpt
  }
}

export interface Sensor {
  _id?: string
  customId?: string // For local use when we create new sensor on editing device generate this id for avoid errors
  sensorName: string
  name: string
  mathExpression?: string
  isDefault?: boolean
}
