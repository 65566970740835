export const ICONS = [
  'CarRed.svg',
  'ContainerRed.svg',
  'LocationGreen.svg',
  'Trailer.svg',
  'TruckBlue.svg',
  'Truck2.svg',
  'motorbike-1.png',
  'vending-machine-1.png',
  'ibeacon-1.png',
  'ibeacon-2.png'
]
